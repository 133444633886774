import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
    },
    {
        path: '/name',
        name: 'NameDiff',
        component: () => import(/* webpackChunkName: "name-diff" */ '../views/NameDiff.vue')
    }
    // ,
    // {
    //     path: '/grade',
    //     name: 'Grade',
    //     component: () => import(/* webpackChunkName: "name-diff" */ '../views/Grade.vue')
    // }
]

const router = new VueRouter({
    routes
})

export default router
